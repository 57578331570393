import { HomeAsync } from 'components/HomeAsync'
import { Scaffold } from './components/Scaffold'
import { ContentWrapper } from './components/styledcomponents'

export const App = () => {
  return (
    <Scaffold>
      <ContentWrapper>
        {/* <HomeAsync /> */}
        <h1 style={{ textAlign: 'center' }}>
          We are on holidays, come back when its cold again
        </h1>
        <h3>
          Built by{' '}
          <a
            href="https://www.roman.ninja"
            target="_blank"
            rel="noreferrer noopener"
            style={{ color: 'black' }}
          >
            Roman
          </a>{' '}
          @{' '}
          <a
            href="https://www.foronered.com"
            target="_blank"
            rel="noreferrer noopener"
            style={{ color: 'black' }}
          >
            For One Red
          </a>
        </h3>
      </ContentWrapper>
    </Scaffold>
  )
}
