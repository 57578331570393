import styled from 'styled-components'
import { DESKTOP_STYLE, MOBILE_STYLE } from './constants'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`
export const HeadingContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;

  ${DESKTOP_STYLE} {
    flex: 1;
    padding-top: 40px;
  }

  ${MOBILE_STYLE} {
    flex: 1;
    width: 100%;
    margin-top: 64px;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  ${DESKTOP_STYLE} {
    width: 150px;
    height: 150px;
  }

  ${MOBILE_STYLE} {
    height: 80px;
    width: 80px;
  }
`

export const ButtonLabel = styled.p`
  margin: 0px;
`

export const Button = styled.button`
  background-color: rgba(174, 222, 254, 0.5);
  border-color: rgb(133, 204, 251);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: black;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }

  ${DESKTOP_STYLE} {
    flex: 1;
    height: 72px;
    padding: 12px;
    border-radius: 5px;
    margin: 8px;
  }

  ${MOBILE_STYLE} {
    max-width: 60px;
    height: 40px;
    padding: 6px;
    border-radius: 5px;
    margin: 4px;
  }
`

export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px darkblue;

  ${DESKTOP_STYLE} {
    flex: 1;
    width: 500px;
    padding: 40px;
  }

  ${MOBILE_STYLE} {
    flex: 1;
    width: 100%;
    padding: 20px;
  }
`

export const StatsEntry = styled.div`
  display: flex;
  flex: 0 0 50px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
`

export const HomeWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${DESKTOP_STYLE} {
    padding: 0px 80px;
  }

  ${MOBILE_STYLE} {
    padding: 16px;
  }
`

export const WrapperDynamic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 16px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 900px;
  justify-content: space-evenly;

  ${DESKTOP_STYLE} {
  }

  ${MOBILE_STYLE} {
    padding: 0px;
    margin-bottom: 16px;
  }
`

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-direction: row;
`

export const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1100px;

  ${DESKTOP_STYLE} {
    flex-direction: row;
    align-items: center;
    margin: 32px 0px;
  }

  ${MOBILE_STYLE} {
    flex-direction: column;
    justify-content: flex-start;
  }
`
export const LegendRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${DESKTOP_STYLE} {
    flex: 1;
  }

  ${MOBILE_STYLE} {
    width: 100%;
    max-width: 720px;
  }
`
export const LegendCircle = styled.div`
  border-radius: 50%;

  ${DESKTOP_STYLE} {
    margin: 0px 16px 0px 32px;
    height: 20px;
    width: 20px;
  }

  ${MOBILE_STYLE} {
    margin: 0px 8px 0px 16px;
    height: 16px;
    width: 16px;
  }
`

export const BlobCircle = styled.div`
  background: #3a993a;
  border-radius: 50%;
  margin: 16px;
  height: 10px;
  width: 10px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #3a993a;
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`
