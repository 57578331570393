import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }

  /* #root {
    margin: 0;
    padding: 0;
    min-height: 600px;
    display: flex;
    flex: 1;
    overflow: hidden;
  } */

  svg {
    overflow: visible;
  }

  body {
    margin: 0;
    padding: 0px;
    /* height: 100vh; */
    /* display: flex; */
  }

  * {
    box-sizing: border-box;
  }

  button {
    font-family: Gemunu Libre;
    font-size: 20px;
    font-weight: 300;
    color: black;
  }

  h1, h2, h3, h4, h5 {
    font-family: Gemunu Libre;
  }

`
